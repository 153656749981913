import { arrayEmpty } from 'src/utils/helper';

import SectionFullHWtab from 'src/components/sections/section-full-h-wtab';
import WidgetCarousel from 'src/components/widgets/widget-carousel';
import SectionFullH from 'src/components/sections/section-full-h';
import SectionFullV from 'src/components/sections/section-full-v';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DynamicAds, { KEY_ADS_POSITION } from 'src/components/ads/dynamic-ads';
// import SectionHighlight from '../section-highlight';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { TAGS_DATA } from 'src/redux/navSlice';
import { SHOW_COMMEMORATE } from 'src/constants';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
const SectionWorldCup = dynamic(
  import('../section-event/section-world-cup/section-world-cup')
);
const SectionOne = ({ data, ads, dataTagWorldCup, dataSetWorldCup }) => {
  if (!data) return null;
  const dispatch = useDispatch();
  const router = useRouter();
  const { block1, block2, block3, headerLatestNews, newHighlight, tags } = data;

  useEffect(() => {
    (async () => {
      try {
        await dispatch(TAGS_DATA(tags));
      } catch (err) {
        console.error('==========> SECTION_ONE ERROR', err);
      }
    })();
  }, []);

  const cloneHighlights = {
    heading: 'ข่าวแนะนำ',
    data: newHighlight && [...newHighlight]
  };

  const cloneLatestNews = {
    heading: 'ข่าวล่าสุด',
    link: '/category/latest-news',
    data: headerLatestNews && [...headerLatestNews]
  };

  const cloneBlock1 = { ...block1 };
  const cloneBlock2 = { ...block2 };
  const cloneBlock3 = {
    ...block3,
    block1: [...block3.data.slice(0, 4)],
    block2: [...block3.data.slice(4, 8)]
  };

  return (
    <>
      <section id='section-1' className='section-wrapper'>
        <div
          className='container'
          style={{
            filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
          }}
        >
          <div className='row'>
            {/* -------------------------------- HIGHLIGHT ------------------------------- */}
            {!arrayEmpty(cloneHighlights.data) && (
              <>
                <h2 className='hide'>Highlights</h2>
                <SectionFullHWtab
                  showHeading={false}
                  data={[{ ...cloneHighlights }]}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    section: 'section-1',
                    position: 'ใต้:header'
                  }}
                />
              </>
            )}
            {/* -------------------------------------------------------------------------- */}
          </div>
        </div>
      </section>
      <section id='section-2' className='section-wrapper on-page'>
        {!arrayEmpty(cloneLatestNews.data) && (
          <div
            className='section-wrapper  --bg-main'
            style={{
              filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
            }}
          >
            <div className='bg-lastest-new'>
              <div className='container'>
                {/* ------------------------------ LASTEST_NEWS ------------------------------ */}
                <div className='mb-4'>
                  <WidgetCarousel
                    noneBg={false}
                    heading={cloneLatestNews?.heading}
                    data={cloneLatestNews.data}
                    path={cloneLatestNews.link}
                    slideShow={4}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'section-2',
                      position: 'ใต้:ข่าวแนะนำ'
                    }}
                  />
                </div>
                {/* -------------------------------------------------------------------------- */}
              </div>
            </div>
          </div>
        )}
      </section>
      {/* ---------------------------- ADS : INARTICLE - 1 ----------------------------- */}
      <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />
      {!_.isEmpty(dataTagWorldCup) && !_.isEmpty(dataSetWorldCup) && (
        <SectionWorldCup
          id='section-world-cup'
          data={dataTagWorldCup}
          spacial={dataSetWorldCup}
        />
      )}
      <section id='section-3' className='section-wrapper on-page'>
        {/* <div className='section-wrapper mt-4 mt-md-5'> */}
        <div className='section-wrapper'>
          <div
            className='container'
            style={{
              filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
            }}
          >
            <div className='row tab-space2'>
              {/* --------------------------------- BLOCK-1 -------------------------------- */}
              {!arrayEmpty(cloneBlock1?.data) && (
                <div className='col-md-6 col-12 mb-4'>
                  <SectionFullH
                    cardV={true}
                    headingColor='--persian-green'
                    data={cloneBlock1?.data}
                    heading={cloneBlock1?.title}
                    path={cloneBlock1?.link}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'section-3',
                      position: 'ใต้:ข่าวล่าสุด',
                      data: {
                        block: '1'
                      }
                    }}
                  />
                </div>
              )}
              {/* --------------------------------- BLOCK-2 -------------------------------- */}
              {!arrayEmpty(cloneBlock2?.data) && (
                <div className='col-md-6 col-12 mb-4'>
                  <SectionFullH
                    cardV={true}
                    headingColor='--red'
                    data={cloneBlock2?.data}
                    heading={cloneBlock2?.title}
                    path={cloneBlock2?.link}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'section-3',
                      position: 'ใต้:ข่าวล่าสุด',
                      data: {
                        block: '2'
                      }
                    }}
                  />
                </div>
              )}
            </div>
            {/* --------------------------------- BLOCK-3 -------------------------------- */}
            {/* <SectionFullV
              heightImg='--small'
              data={cloneBlock3}
              //! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'section-1',
                position: 'ใต้:block-2',
                data: {
                  block: '3'
                }

              }}
            /> */}
          </div>
        </div>
      </section>
      <section id='section-4' className='section-wrapper on-page'>
        <div className='section-wrapper'>
          <div
            className='container'
            style={{
              filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
            }}
          >
            <SectionFullV
              heightImg='--small'
              data={cloneBlock3}
              //! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                section: 'section-4',
                position: 'ใต้:block-3'
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionOne;
