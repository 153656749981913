import CardFull from 'src/components/cards/card-full';
import { arrayEmpty } from 'src/utils/helper';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';

const SectionFull = ({
  data,
  path,
  heading,
  headingColor = '',
  column = 'col-lg-3 col-md-6 col-6',
  heightImg = '',
  eventDataLayer
}) => {
  if (arrayEmpty(data)) return null;
  return (
    <>
      <div className='row tab-space1 mb-25 mb-lg-0'>
        <div className='col-12'>
          {heading && (
            <div className={`section-title ${headingColor}`}>
              <HeadingTag
                heading={heading}
                type={KEY_HEADING_TAG.DEFAULT}
                path={path}
              />
            </div>
          )}
        </div>
        {data.map((item, index) => (
          <div key={index} className={column}>
            <CardFull
              showHeading={false}
              showHeadingTag={false}
              data={item}
              path={item.link}
              heightImg={heightImg}
              //! DATALAYER
              eventDataLayer={{
                ...eventDataLayer,
                data: {
                  ...eventDataLayer?.data,
                  title: item?.title,
                  index: index + 1
                }
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionFull;
