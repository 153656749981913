import Carousel from 'react-slick';
import CardFull from 'src/components/cards/card-full';
import { arrayEmpty } from 'src/utils/helper';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';

const WidgetCarousel = ({
  data = [],
  heading,
  noneBg = true,
  path = null,
  slideShow = 1,
  eventDataLayer
}) => {
  const dataLength = data.length > slideShow;
  const settings = {
    dots: false,
    infinite: dataLength,
    speed: 1000,
    slidesToShow: slideShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideShow,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {heading && <div className={`section-title --red ${!noneBg ? '--nonebg' : ''}`}>
        {!noneBg
          ? <HeadingTag noneBg={noneBg} path={path} heading={heading} type={KEY_HEADING_TAG.ONBACKGROUND} />
          : <HeadingTag path={path} heading={heading} type={KEY_HEADING_TAG.DEFAULT} />}
      </div>}
      <div className='carousel-wrapper'>
        <Carousel {...settings} >
          {
            !arrayEmpty(data) && data.map((item, index) =>
            <div key={index} className={slideShow > 1 && 'pe-1'}>
              <CardFull
                showBadge={false}
                data={item}
                path={item.link}
                 //! DATALAYER
                 eventDataLayer={{
                   ...eventDataLayer,
                   data: {
                     ...eventDataLayer?.data,
                     title: item?.title,
                     carousel: index + 1,
                     heading: heading
                   }
                 }}
              />
            </div>)
          }
        </Carousel>
      </div>
    </>
  );
};

export default WidgetCarousel;
