import CardH from 'src/components/cards/card-h';
import { arrayEmpty } from 'src/utils/helper';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';

const SectionH = ({
  data = [],
  path,
  heading,
  headingColor = '',
  eventDataLayer
}) => {
  if (arrayEmpty(data)) return null;
  return (
    <>
      <div className='row tab-space1 mb-25'>
        <div className='col-12'>
          {heading &&
            <div className={`section-title ${headingColor}`}>
              <HeadingTag path={path} heading={heading} type={KEY_HEADING_TAG.LOTTERY} />
            </div>
          }
        </div>
        {
          !arrayEmpty(data) && data.map((item, index) =>
          <div key={index}>
            <CardH
              data={item}
              path={item.link}
              showBlurb={false}
               //! DATALAYER
               eventDataLayer={{
                 ...eventDataLayer,
                 data: {
                   ...eventDataLayer?.data,
                   title: item?.title,
                   index: index + 5
                 }
               }}
            />
          </div>)
        }
      </div>
    </>
  );
};

export default SectionH;
