import CardFull from 'src/components/cards/card-full';
import CardH from 'src/components/cards/card-h';
import CardV from '../cards/card-v';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';

const SectionFullH = ({
  data,
  path,
  heading,
  FullH = false,
  cardV = false,
  headingColor = '',
  eventDataLayer
}) => {
  const cloneBlock1 = data ? [...data] : [];
  const firstBlock1 = Array.isArray(cloneBlock1) ? cloneBlock1.shift() : [];
  const secondBlock1 = Array.isArray(cloneBlock1) ? cloneBlock1.slice(0, 3) : [];

  return (
    <>
      <div className={`section-title ${headingColor}`}>
        <HeadingTag
          type={KEY_HEADING_TAG.DEFAULT}
          path={path}
          heading={heading}
        />
      </div>
      <div className='row'>
        <div className='col-12 mb-3'>
        {cardV
          ? <CardV
            cardV={cardV}
            data={firstBlock1}
            path={firstBlock1.link}
            //! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              data: {
                ...eventDataLayer?.data,
                title: firstBlock1?.title,
                heading: heading
              }

            }}
          />
          : <CardFull
            showHeadingTag={false}
            data={firstBlock1}
            path={firstBlock1.link}
              //! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              data: {
                ...eventDataLayer?.data,
                title: firstBlock1?.title,
                heading: heading
              }
            }}
          /> }
        </div>
        <div className='col-12'>
          {secondBlock1.map((item, index) =>
          <CardH
            FullH={FullH}
            width={true}
            key={index}
            data={item}
            showBlurb={false}
            path={item.link}
             //! DATALAYER
             eventDataLayer={{
               ...eventDataLayer,
               data: {
                 ...eventDataLayer?.data,
                 title: item?.title,
                 index: index + 2,
                 heading: heading
               }

             }}

          />)}
        </div>
      </div>
    </>
  );
};

export default SectionFullH;
