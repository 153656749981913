import { arrayEmpty } from 'src/utils/helper';
// import HeadingSection from 'src/components/heading/heading-section';
import SectionFull from 'src/components/sections/section-full';
import SectionH from 'src/components/sections/section-h';
import HeadingTag, { KEY_HEADING_TAG } from '../heading/heading-tag';

const SectionFullV = ({ data = null, headingColor = '', heightImg = '', eventDataLayer }) => {
  if (!data) return null;
  const { title, link, block1, block2 } = data;
  return (
    <>
      <div className='section-full-v'>
        <div className='row tab-space1'>
          {title && (
            <div className={`section-title ${headingColor}`}>
              <HeadingTag
                heading={title}
                type={KEY_HEADING_TAG.SECTION_SECTION}
                path={link}
              />
            </div>
          )}
          {!arrayEmpty(block1) && (
            <div className='col-lg-8 col-12 mb-4'>
              <SectionFull
                showHeading={false}
                heightImg={heightImg}
                column='col-6'
                data={block1}
                //! DATALAYER
                eventDataLayer={{
                  ...eventDataLayer,
                  data: {
                    heading: title
                  }
                }}
              />
            </div>
          )}
          {!arrayEmpty(block2) && (
            <div className='col-lg-4 col-12 ps-lg-4 ps-md-0 mb-3'>
              <SectionH data={block2}
              //! DATALAYER
                eventDataLayer={{
                  ...eventDataLayer,
                  data: {
                    heading: title
                  }
                }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SectionFullV;
